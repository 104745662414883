// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bw_k5";
export var caseStudyBackground__bgColor = "bw_kW";
export var caseStudyBackground__lineColor = "bw_kX";
export var caseStudyCta__bgColor = "bw_lX";
export var caseStudyHead__imageWrapper = "bw_kV";
export var caseStudyProjectsSection = "bw_k6";
export var caseStudyQuote__bgLight = "bw_k3";
export var caseStudyQuote__bgRing = "bw_k2";
export var caseStudyTechSection = "bw_lf";
export var caseStudyVideo = "bw_lg";
export var caseStudyVideo__ring = "bw_k8";
export var caseStudy__bgDark = "bw_kT";
export var caseStudy__bgLight = "bw_kS";